import React, {Component} from 'react';
import Lists from './components/Lists';
import axios from "axios";

class App extends Component {
    state = {
        lists: []
    };
    componentDidMount() {

        // get the storeID and token from URL
        let urlPath = window.location.pathname ;
        console.log(urlPath);
        var ar = urlPath.split('/');
        var storeId = ar[1];
        var token = ar[2]; 
            axios
            .get('https://api.intelikart.com/api/notifications?storeId='+storeId,{
                headers: {
                  Authorization: 'Bearer ' + token
                }
            })
            .then((res) => {
                //console.log(res)
               this.setState({ lists: res.data.data })
            })
            .catch((err) => {
              console.log(err)
            });
    }

    markAsRead(notifID){
        console.log(notifID);

        let urlPath = window.location.pathname ;
        console.log(urlPath);
        var ar = urlPath.split('/');
        var storeId = ar[1];
        var token = ar[2]; 

        axios
            .get('https://api.intelikart.com/api/notifications-markread?storeId='+storeId+'&notificationId='+notifID,{
                headers: {
                  Authorization: 'Bearer ' + token
                }
            })
            .then((res) => {
                
                //console.log(res)
               //this.setState({ lists: res.data.data })
            })
            .catch((err) => {
              console.log(err)
            });

    }

    render() {
        return (
            <Lists lists={this.state.lists} markAsRead={this.markAsRead}/>
        )
    }

    

    
}

export default App;