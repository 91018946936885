import React from 'react'
import { Eclipse } from "react-loading-io";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

const Lists = ({ lists, markAsRead }) => {

    if (lists === undefined || lists.length === 0) {
        return (
            <div style={{ margin: "50px" }}>

                <div className='text-center m-5'>
                    <div>
                        <Eclipse size={100} color='#4286f5' />
                    </div>
                    <div>
                        Loading notifications...
                    </div>
                </div>
            </div>
        )
    }
    else {
        return (
            <div style={{ margin: "5px" }}>

                {lists.map((list) => (

                    <div key={list.id} className={`card w-100 mb-10 ${list.viewed ? "readNotification" : "newNotification"}`}
                        onClick={() => {markAsRead(list.id);}}>
                        <div className="card-body">
                            <p className="card-text">{list.message}</p>
                            {ReactHtmlParser(list.html)}
                        </div>
                    </div>

                ))}
            </div>

        )
    }
};

export default Lists;